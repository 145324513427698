import { render, staticRenderFns } from "./PracticeTheme.vue?vue&type=template&id=34c51158&scoped=true"
import script from "./PracticeTheme.vue?vue&type=script&lang=js"
export * from "./PracticeTheme.vue?vue&type=script&lang=js"
import style0 from "./PracticeTheme.vue?vue&type=style&index=0&id=34c51158&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34c51158",
  null
  
)

export default component.exports