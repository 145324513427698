var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('heade'),_c('div',{staticClass:"com",style:({
    backgroundImage: 'url(' + require('@/assets/' + _vm.backgroud) + ')',
  })},[_c('div',{staticClass:"com_box"},[_c('div',[_c('div',{staticClass:"com-left"},[_c('div',{staticClass:"com-left-top"},[_vm._v(_vm._s(_vm.practice.name))]),_vm._m(0),_c('div',{staticClass:"com-left-foot"},_vm._l((_vm.practice.division_category),function(item,index){return _c('div',{key:index,staticClass:"nice-scroll"},_vm._l((item.division),function(items,indexs){return _c('div',{key:indexs,staticClass:"navgatorLi"},[_c('a',{staticClass:"com-left-foot-but",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.goPracticeDetatil(
                    indexs,
                    items.id,
                    items.division_content_id,
                    items.charge_mode,
                    items.jump_link_url
                  )}}},[_vm._v(_vm._s(items.name))])])}),0)}),0)]),_c('div',{staticClass:"com-right"},[_c('el-button',{attrs:{"type":"text","icon":"el-icon-arrow-left"},on:{"click":function($event){return _vm.$router.back(-1)}}},[_vm._v("返回上一页")]),_c('div',{staticClass:"nice-scroll",domProps:{"innerHTML":_vm._s(_vm.practice.content)}})],1)]),_c('div',{staticClass:"com-btt"},[_c('div',[_c('div',_vm._l((_vm.practiceTheme),function(item,index){return _c('router-link',{key:index,class:item.id == _vm.queryWhere.id ? 'com-bbt-item active' : 'com-bbt-item',attrs:{"to":{
              path: _vm.path,
              query: { id: item.id },
            }}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)])])])]),_c('foot')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"com-left-com"},[_c('div',{staticClass:"com-left-com-item"},[_vm._v("主题总论")])])
}]

export { render, staticRenderFns }